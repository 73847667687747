import React, { useState } from 'react'
import axios from 'axios'
import { useStaticQuery, graphql } from 'gatsby'
import {
  NewsletterForm,
  NewsletterFormType,
  privacyText,
} from '../Forms/NewsletterForm'
import { Container } from '../Layout'
import { Section, SectionProps } from './Section'
import PayperIcon from '../icons/components/Payper.svg.react'

const NEWSLETTER_ENDPOINT =
  'https://api.hsforms.com/submissions/v3/integration/submit/7744677/c2046003-82d2-4d56-a6b0-960ff7e5aecf'

type SectionNewsletterProps = SectionProps & {
  page?: string | null
}

const defaultProps = {
  page: null,
}

const SectionNewsletter = (
  props: SectionNewsletterProps
): React.ReactElement => {
  const { containerClass, page } = props
  const [status, setStatus] = useState<string | null>(null)
  const data = useStaticQuery(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title || ``

  const handleSubmit = async (
    values: NewsletterFormType,
    { setSubmitting }: any
  ) => {
    try {
      const {
        data: { inlineMessage },
      } = await axios.post(NEWSLETTER_ENDPOINT, {
        fields: [
          {
            name: 'email',
            value: values.email,
          },
        ],
        context: {
          pageUri: siteTitle,
          pageName: page,
        },
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: privacyText,
          },
        },
      })
      setStatus(inlineMessage)
    } catch (reason) {
      // console.log(reason)
      // handle error
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Section containerClass={containerClass}>
      <Container>
        <div className="bg-dirtyWhite bg-opacity-15 p-5 sm:p-10 rounded-xl">
          <PayperIcon className="fill-current text-white w-28" />

          {/* <div className="grid grid-cols-2"> */}
          <div className="mb-6">
            <p className="text-2xl font-light">
              Der payactive Newsletter rund um unsere Welt des nachhaltigen
              Payments.
            </p>
          </div>

          <div
          // className="max-w-2xl"
          >
            {status ? (
              <p>{status}</p>
            ) : (
              <NewsletterForm handleSubmit={handleSubmit} />
            )}
          </div>
          {/* </div> */}
        </div>
      </Container>
    </Section>
  )
}
SectionNewsletter.defaultProps = defaultProps

export { SectionNewsletter }
